import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header style={{display: 'flex', flexDirection:"row", justifyContent: 'space-between', padding: '0 30px 0 60px', backgroundColor: "teal"}}>
      <h3 style={{ color:'white'}}>Book On Sale</h3>
      <nav >
        <ul style={{display: 'flex', flexDirection:"row", gap: '30px',style: "none"}}>
        <li  style={{style: "none"}}>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          {/* Add more navigation links if needed */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
