import React from 'react';

function FAQ() {
  return (
    <div>
      <h1>Frequently Asked Questions</h1>
      <ul>
        <li>
          <h2>How do I list a book for sale?</h2>
          <p>To list a book for sale, navigate to your account dashboard and click on the "Sell" button. Follow the prompts to enter details about the book, including title, author, condition, and price.</p>
        </li>
        <li>
          <h2>How do I search for books?</h2>
          <p>You can search for books using the search bar located at the top of the page. Simply enter keywords, author names, or book titles to find relevant listings.</p>
        </li>
        {/* Add more FAQ items */}
      </ul>
    </div>
  );
}

export default FAQ;
