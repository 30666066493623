import './App.css';
import LandingPage from './pages/LandingPage';
import ContactUs from './pages/ContactUsPage';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Header from './components/header';
import FAQ from './pages/Faq';


function App() {
  return (
    <div className='App'>
      
<BrowserRouter>
<Header/>
   <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/faq" element={<FAQ/>} />
    </Routes>

    </BrowserRouter>
    </div>
  );
}

export default App;
