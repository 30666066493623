import React from 'react';

function ContactUs() {
  return (
    <div className='App'>
      <h1>Contact Us</h1>
      <p>Have questions or feedback? We'd love to hear from you!</p>
      <form style={{display: 'flex', flexDirection:'column',gap: '10px', justifyContent:'center', alignItems:'center'}}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" />
        
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" />
        
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="4"></textarea>
        
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactUs;
