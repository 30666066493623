import React from 'react';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <h1>Welcome to Book On Sale</h1>
        <p>Your ultimate destination for finding great deals on books!</p>
      </header>
      <main>
        <section className="promo-section">
          <h2>Discover Amazing Deals</h2>
          <p>Explore our vast collection of books on sale. Find your next favorite read at a discounted price!</p>
          <a href="#books" className="btn-primary">Browse Books</a>
        </section>
        <section id="books" className="books-section">
          <h2>Featured Books</h2>
          <div className="book-list">
            {/* Your featured books could be listed here */}
            <div className="book-card">
              <img src="book1.jpg" alt="Book 1" />
              <h3>Book Title 1</h3>
              <p>Author: Author Name</p>
              <p>Price: $9.99 <span className="discount"> $6.99</span></p>
              <button className="btn-secondary">Add to Cart</button>
            </div>
            <div className="book-card">
              <img src="book2.jpg" alt="Book 2" />
              <h3>Book Title 2</h3>
              <p>Author: Author Name</p>
              <p>Price: $12.99 <span className="discount"> $8.99</span></p>
              <button className="btn-secondary">Add to Cart</button>
            </div>
            {/* Add more book cards as needed */}
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Book On Sale. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default LandingPage;
